import React, { useState } from "react";
import "../PagesCss/Default.css";
import ImageContainer from "./ImageContainer";

const generateCaptcha = () => {
  const chars = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";
  let captcha = "";
  for (let i = 0; i < 5; i++) {
    captcha += chars[Math.floor(Math.random() * chars.length)];
  }
  return captcha;
};

const PesLhpPrint = () => {
  const [captchaText, setCaptchaText] = useState(generateCaptcha());
  const [userInput, setUserInput] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [visitedColors, setVisitedColors] = useState([]);
  const currentYear = new Date().getFullYear();

  const handleCaptchaSubmit = () => {
    if (userInput.toUpperCase() === captchaText) {
      setCaptchaVerified(true);
    } else {
      alert("Captcha incorrect. Try again!");
      refreshCaptcha();
    }
  };

  const refreshCaptcha = () => {
    setCaptchaText(generateCaptcha());
    setUserInput("");
  };

  // Function to generate random font size
  const getRandomFontSize = () => {
    const sizes = [24, 28, 32, 36, 40]; // Random font sizes
    return sizes[Math.floor(Math.random() * sizes.length)];
  };

  return (
    <>
      {!captchaVerified ? (
        <>
          <br />
          <br />
          <br />
          <div
            className="captcha-container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: "#f8f8f8",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              width: "350px",
              margin: "0px auto",
            }}
          >
            <p style={{ color: "red" }}>
              <span style={{ fontWeight: "bold" }}>CAPTCHA</span> for security
              check, I’m not a robot
            </p>
            <div
              style={{
                background: "#ffffff",
                padding: "10px",
                minHeight: "100px",
                fontSize: "30px",
                fontWeight: "bold",
                textAlign: "center",
                letterSpacing: "3px",
                width: "200px",
                margin: "10px auto",
                fontFamily: "Comic Sans MS cursive",
                textTransform: "uppercase",
                color: "#0079F4",
                border: "2px solid #0079F4",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                transform: "rotate(-8deg)", // Adjust rotation as needed
              }}
            >
              {captchaText.split("").map((char, index) => (
                <span
                  key={index}
                  style={{
                    fontSize: `${getRandomFontSize()}px`,
                    transform: `rotate(${Math.random() * 20 - 10}deg)`, // Random rotation for each character
                    margin: "0 2px",
                  }}
                >
                  {char}
                </span>
              ))}
              <button
                onClick={refreshCaptcha}
                style={{
                  position: "absolute",
                  right: "-30px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "18px",
                  color: "#0079F4",
                }}
              >
                &#x21bb;
              </button>
            </div>
            <h3 style={{ fontSize: "14px", marginTop: "10px" }}>
              Please enter the text below
            </h3>
            <input
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder="Enter text"
              style={{
                padding: "10px",
                textAlign: "center",
                width: "200px",
                borderRadius: "5px",
                border: "1px solid #0079F4",
              }}
            />
            <button
              onClick={handleCaptchaSubmit}
              style={{
                marginTop: "10px",
                padding: "10px 20px",
                cursor: "pointer",
                background: "#0079F4",
                color: "white",
                border: "none",
                borderRadius: "5px",
                fontSize: "16px",
              }}
            >
              Verify
            </button>
          </div>
        </>
      ) : (
        <div id="layoutSidenav">
          <div id="layoutSidenav_content">
            <main>
              <div className="container-xl px-4 mt-4">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card mb-4">
                      <ImageContainer selectedLanguage="en" />
                      <div className="card-body">
                        <p
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "13.5px",
                          }}
                        >
                          Welcome to the Dignity Memorial Legal Service Plan.
                          Bienvenue aux Services juridiques Réseau Dignité.
                        </p>
                        <hr />
                        <div className="container">
                          <div className="row">
                            <div className="col-md-5">
                              <a
                                className="printLinks"
                                href="/LSP Handbook.pdf"
                                target="_blank"
                                onClick={() =>
                                  setVisitedColors([...visitedColors, 9])
                                }
                                style={{
                                  color: visitedColors.includes(9)
                                    ? "lightblue"
                                    : "#0079F4",
                                  cursor: "pointer",
                                }}
                              >
                                Access the Membership Handbook
                              </a>
                            </div>
                            {/* <div
                              className="col-md-5"
                              style={{ marginBottom: "7px", fontSize: "14px" }}
                            >
                              Information sheet – LSP Handbook
                            </div> */}
                          </div>
                        </div>
                        <br />
                        <div className="container">
                          <div className="row">
                            <div className="col-md-5">
                              <a
                                className="printLinks"
                                onClick={() => {
                                  let newTab = window.open(
                                    "/LSP Handbook FR.pdf",
                                    "_blank"
                                  );
                                  if (newTab) {
                                    setTimeout(() => {
                                      newTab.document.title =
                                        "Services juridiques manuel du membres";
                                    }, 1000);
                                  }
                                  setVisitedColors([...visitedColors, 8]);
                                }}
                                style={{
                                  color: visitedColors.includes(8)
                                    ? "lightblue"
                                    : "#0079F4",
                                  cursor: "pointer",
                                }}
                              >
                                Consultez le Manuel du membre
                              </a>
                            </div>
                            {/* <div
                              className="col-md-5"
                              style={{ marginBottom: "7px", fontSize: "14px" }}
                            >
                              Information sheet – LSP Handbook
                            </div> */}
                          </div>
                        </div>
                        <hr />
                        <a
                          className="mobClickHere"
                          style={{ color: "#0061f2", cursor: "pointer" }}
                          href="/"
                        >
                          Go To The Main Page.
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <footer className="footer-admin mt-auto footer-light">
              <div className="container-xl px-4">
                <div className="row">
                  <div className="col-md-5 small">
                    Copyright © Progressive Estate Solutions Inc. {currentYear}
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      )}
    </>
  );
};

export default PesLhpPrint;
